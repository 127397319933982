import React from 'react';
import logo from './logo-alpha.png';
import spotify from './store-Spotify.svg';
import apple from './store-AppleMusic.svg';
import deezer from './store-Deezer.svg';
import itunes from './store-ITunes.svg';
import tidal from './store-Tidal.svg';
import soundcloud from './store-soundcloud.svg';
import './App.scss';

function App() {
    return (
        <React.Fragment>

        <div className="App">
            <div className={"background-image"} >&nbsp;</div>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="FNXCorp."/>
                <iframe src="https://open.spotify.com/embed/artist/0CaNhyektYZrxGXaOmOFql" width="300" height="30%"
                        frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                <p>
                    Find our music in the streaming service of your preference
                </p>
                <div className={"flex-container"}>
                    <div>
                        <a
                            href={"https://open.spotify.com/artist/0CaNhyektYZrxGXaOmOFql?si=aAQzp117RguOYYXxFII9BQ"}
                            target={"_blank"}
                        >
                            <img src={spotify}/>
                        </a>
                    </div>
                    <div>
                        <a
                            href={"https://music.apple.com/us/artist/fnxcorp/1471733831"}
                            target={"_blank"}
                        >
                        <img src={apple}/>
                        </a>
                    </div>
                    <div>
                        <a
                            href={"https://www.deezer.com/en/artist/68991642"}
                            target={"_blank"}
                        >
                        <img src={deezer}/>
                        </a>
                    </div>
                    <div>
                        <a
                            href={"https://music.apple.com/us/artist/fnxcorp/1471733831"}
                            target={"_blank"}
                        >
                        <img src={itunes}/>
                        </a>
                    </div>
                    <div>
                        <a
                            href={"https://tidal.com/browse/artist/16205219"}
                            target={"_blank"}
                        >
                        <img src={tidal}/>
                        </a>
                    </div>
                    <div>
                        <a
                            href={"https://soundcloud.com/fnxcorp"}
                            target={"_blank"}
                        >
                        <img src={soundcloud} style={{"width": "50px"}}/>
                        </a>
                    </div>
                </div>
            </header>
        </div>
    </React.Fragment>
    );
}

export default App;
